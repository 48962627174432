.webinar-theme {
  margin-top: 240px;
  @media (max-width: 991px) {
    margin-top: 128px;
  }
}

.webinar-theme__head {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 25px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);

  @media (max-width: 991px) {
    display: block;
    padding-bottom: 12px;
  }
}

.webinar-theme__content {
  padding-top: 40px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 140px;
  background: #495266;
  border-radius: 5px 5px 100px 5px;

  @media (max-width: 991px) {
    padding-bottom: 36px;
    border-radius: 5px;
  }

  @media (max-width: 424px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.webinar-theme__content {
  .title-2 h2 {
    color: #fff;
  }

  @media (max-width: 991px) {
    .title-2 {
      margin-bottom: 20px;
    }
  }
}

.info-row {
  display: flex;
  justify-content: space-between;

  & + .info-row {
    margin-top: 80px;
  }

  @media (max-width: 991px) {
    display: block;

    & + .info-row {
      margin-top: 60px;
    }
  }
}

.info-row__column {
  width: 47%;
  max-width: 555px;
  padding: 20px 40px 36px 30px;

  @media (max-width: 991px) {
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 20px;
    padding-right: 20px;

    & + .info-row__column {
      margin-top: 60px;
    }
  }
}

.info-row__column--p0 {
  padding-left: 0;
  padding-right: 0;
}

.info-row__column--bg {
  padding: 20px 40px 36px 30px;
  background: #5b6375;
  border-radius: 5px;
  padding-top: 20px;
  padding-bottom: 35px;

  @media (max-width: 767px) {
    padding: 20px;
    padding-bottom: 40px;
  }
}

.webinar-theme__text {
  padding-top: 100px;

  @media (max-width: 767px) {
    padding-top: 61px;
  }

  p.text-100 {
    color: @green;
    margin-bottom: 20px;
  }
}
.webinar-theme__content {
  .title-3 h3,
  p {
    color: #fff;
  }
  .title-3 {
    margin-bottom: 14px;
    @media (max-width: 767px) {
      margin-bottom: 20px;
    }
  }
}
.info-row__column--border {
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.3);

  @media (max-width: 991px) {
    padding-top: 20px;
    padding-bottom: 40px;
  }
}

.info-row__column--300 {
  width: 300px;
  padding-right: 30px;
  @media (max-width: 767px) {
    width: 100%;
    max-width: 335px;
  }
}

.info-row__column--70 {
  min-width: 68%;
  max-width: 810px;
  @media (min-width: 992px) and (max-width: 1199px) {
    min-width: 60%;
  }
}
.webinar-theme__footer {
  margin-top: 90px;
  position: relative;
  @media (max-width: 767px) {
    margin-top: 60px;
  }
  .badge {
    position: absolute;
    background-color: @blue;
    top: 17px;
    left: 30px;
    z-index: 5;
  }
}
.emoji-row {
  display: flex;
  overflow: hidden;
  justify-content: center;
  margin-bottom: 50px;
}
.emoji-row__item {
  min-width: 200px;
  max-width: 200px;
  & + .emoji-row__item {
    margin-left: 60px;
  }
  @media (max-width: 1199px) {
    min-width: 146px;
    max-width: 146px;
    & + .emoji-row__item {
      margin-left: 20px;
    }
  }
  @media (max-width: 767px) {
    img {
      position: relative;
      left: -80px;
    }
  }
}
.info-row__column.info-row__column-emoji {
  padding-left: 0;
  padding-right: 0;
  background: #252a34;
  box-shadow: 0px 10px 40px rgba(37, 42, 52, 0.25);
  border-radius: 15px;
  padding-top: 115px;
  padding-bottom: 60px;
  position: relative;
  @media (max-width: 767px) {
    padding-top: 102px;
    padding-bottom: 50px;
  }
}

.emoji-row__text {
  padding-left: 30px;
  padding-right: 40px;
  @media (max-width: 767px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.textbox a.text-2 {
  color: #fff;
  display: inline-block;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  margin-top: 20px;
  .tr;
  &:hover {
    color: @red;
    border-bottom: 1px solid rgba(237, 73, 24, 0.2);
  }
}
.info-row__logo {
  position: relative;
  margin-bottom: 40px;
  margin-top: auto;
  width: 240px;
  &:after {
    content: "";
    top: 35px;
    bottom: 35px;
    left: 38px;
    right: 38px;
    transform: rotate(-17deg);
    position: absolute;
    background-color: #fff;
    z-index: 1;
  }
  img {
    z-index: 2;
    position: relative;
  }
}
.info-row__column--flex {
  // align-items: flex-end;
  display: flex;
  flex-direction: column;
  padding-bottom: 60px;
  padding-left: 0;
  padding-right: 0;
}
