/* colors */
@green: #6EB883;
@pr: #252A34;
@grey:#495266;
@lg:#5B6375;
@fg:#363E4D;
@blue:#2F80ED;
@red:#ED805F;
@orange: #ED6E47;
@pink:#FAC7EA;
@rose:#F4E3E0;
@light:#EAF0F5;
@error: #FFD8CC;
@er-txt: rgb(237, 73, 24);
@vk: #4D76A1;
@tg:#37AEE2;



@op1:rgba(37, 42, 52, .1);
@op4:rgba(37, 42, 52, .4);


/* fonts*/
@cm:'Commissioner';
@rr:'Roboto Regular';
@rb:'Roboto Bold';


/* class*/
.ma{
	margin-left: auto;
	margin-right: auto;
}
.tr{
	transition: .5s;
}
.flex-block{
	display: flex;
	justify-content: space-between;
	align-items: center;
}