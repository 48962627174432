.warning {
  margin-top: 240px;

  @media(max-width: 991px){
    margin-top: 142px;
  }
}

.warning.warning--2 {
  margin-bottom: 240px;
  margin-top: 179px;

  @media(max-width: 991px){
    margin-top: 79px;
    margin-bottom: 199px;
  }
}

.warning.warning--3{
  margin-bottom: 20px;
  margin-top: 100px;

  @media(max-width:991px){
    margin-top: 150px;
  }

  .badge{
    background-color: @blue;
    margin-left: 24px;

    @media(max-width: 991px){
      margin-left: 0;
      margin-top: 20px;
    }
  }

  .warning-text--flex{
    margin-top: 25px;
    display: flex;
    align-items: center;

    @media(max-width: 991px){
      display: block;
    }
  }

}

.warning-text {

  p.decor-text {
    line-height: 1.27;
  }

  p + p {
    margin-top: 30px;

    @media(max-width: 991px){
      margin-top: 15px;
    }
  }
}