.footer{
	
	padding-bottom: 70px;
	.container{
		padding-top: 45px;
		border-top: 1px solid rgba(73, 82, 102,.5);
	}
	
}
.footer-nav{
	display: flex;
	align-items: center;
	@media(max-width:1199px){
		flex-direction: column;
		align-items: flex-start;
		
	}
}
.footer-nav__col{
	display: flex;
	align-items: center;
	@media(max-width:767px){
		display: block;
	}
}
.footer-logo{
	margin-right: 60px;
	@media(max-width:767px){
		margin-right: 0px;
		margin-bottom: 14px;
	}
}
.rules-doc{
	margin-left: auto;
	@media(max-width:1199px){
		margin-top: 38px;
		margin-left: 0;
		
	}
	a+a{
		margin-left: 62px;
		@media(max-width:767px){
			margin-top: 24px;
			margin-left: 0px;
		}
	}
}
.footer-bottom{
	margin-top: 22px;
	display: flex;
	@media(max-width:991px){
		flex-direction: column;
		margin-top: 60px;
		align-items: flex-start;
		.footer-right{
			margin-top: 40px;
		}
	}
	.flex-block;
	p{
		opacity: .5;
		font-size: 16px;
		line-height: 22px;
	}
}
.rules-doc{
	display: flex;
	@media(max-width:767px){
		display: block;
		margin-left: 0;
		a{
			width: fit-content;
			display: block;	
		}
	}
}