/* colors */
/* fonts*/
/* class*/
.ma {
  margin-left: auto;
  margin-right: auto;
}
.tr {
  transition: .5s;
}
.flex-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
* {
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  color: #252A34;
  font-family: 'Commissioner', sans-serif;
  font-weight: normal;
  font-size: 16px;
  position: relative;
  background-color: #F5F5F7;
}
body {
  max-width: 1536px;
  margin-left: auto;
  margin-right: auto;
}
body.noscroll {
  overflow: hidden;
}
#overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  z-index: 10;
}
#overlay.active {
  display: block;
  backdrop-filter: blur(3px);
}
a,
a:hover {
  text-decoration: none;
}
ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
h1,
h2,
h3,
h4,
p {
  padding: 0;
  margin: 0;
}
img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}
svg {
  max-width: 100%;
}
input,
button {
  outline: none;
}
button {
  padding: 0;
  background-color: transparent;
  border: none;
  box-shadow: none;
  outline: none;
}
button:focus {
  outline: none;
}
.container {
  width: 1290px;
  max-width: 1290px;
  padding-left: 0;
  padding-right: 0;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 1364px) {
  .container {
    width: 1140px;
    max-width: 1140px;
  }
}
@media (max-width: 1199px) {
  .container {
    width: 930px;
    max-width: 930px;
  }
}
@media (max-width: 991px) {
  .container {
    width: 708px;
    max-width: 708px;
  }
}
@media (max-width: 767px) {
  .container {
    width: 540px;
    max-width: 540px;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 599px) {
  .container {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}
.container--dark {
  background: #252A34;
}
@media (max-width: 424px) {
  .container.container--p0 {
    padding-left: 0;
    padding-right: 0;
  }
}
.visible-mobile {
  display: none;
}
@media (max-width: 767px) {
  .visible-mobile {
    display: block;
  }
}
.visible-laptop {
  display: block;
}
@media (max-width: 1199px) {
  .visible-laptop {
    display: none;
  }
}
.hide-laptop {
  display: none;
}
@media (max-width: 1199px) {
  .hide-laptop {
    display: block;
  }
}
.visible-desk {
  display: block;
}
@media (max-width: 767px) {
  .visible-desk {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hide-tablet {
    display: none;
  }
}
/*типографика*/
.title-1 h1,
.title-2 h2,
.title-3 h3 {
  color: #252A34;
  font-family: 'Commissioner', sans-serif;
  font-weight: bold;
}
.title-1 h1 {
  font-size: 90px;
  line-height: 1.11;
}
@media (max-width: 1364px) {
  .title-1 h1 {
    font-size: 76px;
  }
}
@media (max-width: 1199px) {
  .title-1 h1 {
    font-size: 66px;
  }
}
@media (max-width: 991px) {
  .title-1 h1 {
    font-size: 56px;
  }
}
@media (max-width: 767px) {
  .title-1 h1 {
    font-size: 46px;
  }
}
.decor-text,
.title-2 h2 {
  font-size: 60px;
  line-height: 1.17;
}
@media (max-width: 1364px) {
  .decor-text,
  .title-2 h2 {
    font-size: 50px;
  }
}
@media (max-width: 991px) {
  .decor-text,
  .title-2 h2 {
    font-size: 40px;
  }
}
@media (max-width: 767px) {
  .decor-text,
  .title-2 h2 {
    font-size: 36px;
    line-height: 44px;
  }
}
.title-3 h3 {
  font-size: 28px;
  line-height: 1.22;
}
@media (max-width: 767px) {
  .title-3 h3 {
    font-size: 24px;
  }
}
h4 {
  font-size: 24px;
  line-height: 1.25;
  font-weight: bold;
  font-family: 'Commissioner', sans-serif;
}
.green-text {
  color: #6EB883;
}
.red-text {
  color: #ED805F;
}
.text-1 {
  font-size: 28px;
  line-height: 1.43;
  font-family: 'Roboto Regular';
}
@media (max-width: 767px) {
  .text-1 {
    font-size: 20px;
    line-height: 1.3;
  }
}
.text-2 {
  font-size: 20px;
  line-height: 1.4;
  font-family: 'Roboto Regular';
}
.text-3 {
  font-size: 18px;
  line-height: 1.33;
  font-family: 'Roboto Regular';
}
.text-4 {
  font-size: 16px;
  line-height: 1.83;
  font-family: 'Roboto Regular';
}
.text-bold {
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
}
.text-upper {
  text-transform: uppercase;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.05em;
  font-weight: bold;
}
.text-100 {
  font-size: 100px;
  line-height: 0.83;
}
.text-100 sup {
  font-size: 60px;
  font-weight: bold;
}
.text-green {
  color: #6EB883;
}
.badge {
  background-color: #6EB883;
  height: 38px;
  line-height: 38px;
  padding-left: 14px;
  padding-right: 14px;
  color: #fff;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 0.05em;
  font-weight: 700;
  padding-top: 0;
  padding-bottom: 0;
  white-space: nowrap;
}
.badge.badge--red {
  background-color: #ED805F;
}
.text-link {
  color: #252A34;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(37, 42, 52, 0.1);
  transition: .5s;
}
.text-link:hover {
  color: #ed4918;
  border-bottom-color: rgba(237, 73, 24, 0.1);
}
.text-link.text-link--blue {
  color: #2f80ed;
  border-bottom-color: rgba(47, 128, 237, 0.1);
}
.text-link.text-link--blue:hover {
  color: #ed4918;
  border-bottom-color: rgba(237, 73, 24, 0.1);
}
/*menu-toggle*/
.menu-toggle {
  display: none;
  position: relative;
}
@media (max-width: 991px) {
  .menu-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;
    margin-left: 65px;
    width: 54px;
    height: 54px;
    border: 1px solid #E5E5E5;
    border: 1px solid #252A34;
    position: relative;
    border-radius: 5px;
  }
}
.menu-toggle span {
  position: absolute;
  display: block;
  width: 30px;
  height: 2px;
  background-color: #252A34;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}
.menu-toggle span:after,
.menu-toggle span:before {
  content: '';
  position: absolute;
  left: 0;
  height: 2px;
  background-color: #252A34;
  right: 0;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}
.menu-toggle span:after {
  top: -6px;
}
.menu-toggle span:before {
  bottom: -6px;
}
.menu-toggle.active span {
  background-color: transparent;
}
.menu-toggle.active span:after {
  top: 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  background-color: #252A34;
}
.menu-toggle.active span:before {
  bottom: 0;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  background-color: #252A34;
}
@media (max-width: 374px) {
  .preview-content__data p + p {
    margin-left: 20px;
  }
}
@media (max-width: 374px) {
  .preview-content__title .title-1 h1 {
    font-size: 42px;
  }
}
@media (max-width: 374px) {
  .big-button {
    padding-left: 20px;
    padding-right: 20px;
    width: 280px;
  }
}
@media (max-width: 374px) {
  .form-input,
  .form-input--short {
    width: 285px;
  }
}
@media (max-width: 374px) {
  .preview-info__column .text-bold {
    width: 230px;
  }
}
@media (max-width: 776px) {
  .result {
    margin-top: 140px;
  }
}
@media (max-width: 374px) {
  .preview-content__data p + p {
    margin-left: 9px;
  }
}
@media (max-width: 374px) {
  .disclaimer-text {
    width: 290px;
  }
}
p.error {
  display: none;
}
#social-icon {
  display: none;
}
#social-icon--modal {
  display: none;
}
.button {
  display: inline-block;
  cursor: pointer;
  outline: none;
}
.medium-button {
  display: inline-block;
  cursor: pointer;
  color: #fff;
  line-height: 60px;
  background: #ED805F;
  border-radius: 10px;
  height: 60px;
  padding-left: 40px;
  padding-right: 40px;
  transition: .5s;
  font-size: 20px;
  font-family: 'Roboto Regular';
}
.medium-button:hover {
  background: #ED6E47;
  color: #FFF;
}
.big-button {
  display: inline-block;
  cursor: pointer;
  color: #fff;
  line-height: 100px;
  background: #ED805F;
  border-radius: 10px;
  height: 100px;
  padding-left: 80px;
  padding-right: 80px;
  transition: .5s;
  font-size: 24px;
  font-family: 'Roboto Regular';
}
@media (max-width: 767px) {
  .big-button {
    padding-left: 38px;
    padding-right: 38px;
  }
}
.big-button:hover {
  background: #ED6E47;
  color: #FFF;
}
.slim-button {
  height: 54px;
  line-height: 54px;
  background: #ED805F;
  border-radius: 5px;
  display: inline-block;
  cursor: pointer;
  transition: .5s;
  padding-left: 20px;
  padding-right: 20px;
  color: #FFF;
  font-size: 20px;
  font-family: 'Roboto Regular';
}
.slim-button:hover {
  background: #ED6E47;
  color: #FFF;
}
.center-button {
  display: flex;
  justify-content: center;
}
#floating-button {
  position: fixed;
  top: 50%;
  right: 20px;
  z-index: 10;
  display: none;
}
#floating-button.active {
  display: block;
}
.contact-form {
  background-color: #252A34;
  max-width: 1290px;
  border-radius: 15px;
  box-shadow: 0 10px 40px rgba(37, 42, 52, 0.25);
  margin-top: 200px;
  padding-top: 40px;
  color: #fff;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 991px) {
  .contact-form {
    padding-top: 30px;
    margin-top: 140px;
  }
}
.contact-form .success-title {
  display: none;
}
.contact-form .success-icon {
  display: none;
}
.contact-form .subtitle-text__success {
  display: none;
}
.contact-form .success-message {
  display: none;
}
.contact-form .contact-form-title {
  padding-right: 110px;
  padding-left: 220px;
  margin-bottom: 40px;
}
@media (max-width: 991px) {
  .contact-form .contact-form-title {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.contact-form .title-2 h2 {
  color: #ffffff;
  margin-bottom: 20px;
  font-weight: normal;
}
@media (max-width: 991px) {
  .contact-form .title-2 h2 {
    margin-bottom: 30px;
    font-size: 36px;
  }
}
@media (max-width: 374px) {
  .contact-form .title-2 h2 {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .contact-form p.text-2 {
    font-size: 16px;
  }
}
.contact-form .event {
  display: flex;
  font-family: 'Commissioner';
  text-transform: uppercase;
  margin-bottom: 30px;
}
@media (max-width: 991px) {
  .contact-form .event {
    justify-content: space-between;
    margin-bottom: 56px;
  }
}
.contact-form .event .preview-content__data {
  display: flex;
}
.contact-form .event .preview-content__data p + p {
  margin-left: 52px;
}
@media (max-width: 767px) {
  .contact-form .event .preview-content__data p + p {
    margin-left: 9px;
  }
}
.contact-form .event svg {
  margin-right: 15px;
}
.contact-form .text-link {
  color: #ffffff;
  border-bottom: 1px solid #ffffff;
}
.contact-form .text-link:hover {
  color: #ed4918;
  border-bottom-color: rgba(237, 73, 24, 0.1);
}
.contact-subtitle {
  display: flex;
  position: relative;
  justify-content: space-between;
  background-color: #495266;
  max-width: 960px;
  min-height: 100px;
  border-radius: 5px;
  margin-bottom: 50px;
}
@media (min-width: 992px) and (max-width: 1364px) {
  .contact-subtitle {
    display: flex;
  }
}
@media (max-width: 767px) {
  .contact-subtitle {
    display: block;
  }
}
.contact-subtitle .subtitle-text {
  padding-top: 25px;
  padding-left: 30px;
  padding-bottom: 25px;
}
@media (max-width: 767px) {
  .contact-subtitle .subtitle-text {
    width: 100%;
    min-width: 295px;
    font-size: 20px;
    padding-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 0;
  }
}
.contact-subtitle .subtitle-img {
  position: absolute;
  right: 0;
  bottom: 0;
  filter: drop-shadow(0px 10px 40px rgba(37, 42, 52, 0.25));
}
@media (min-width: 992px) and (max-width: 1364px) {
  .contact-subtitle .subtitle-img {
    width: 250px;
  }
}
@media (max-width: 991px) {
  .contact-subtitle .subtitle-img {
    width: 260px;
    right: 0;
    bottom: 0;
  }
}
@media (max-width: 767px) {
  .contact-subtitle .subtitle-img {
    position: relative;
  }
}
.contact-subtitle .subtitle-img .img-mob {
  display: none;
}
@media (max-width: 991px) {
  .contact-subtitle .subtitle-img .img-mob {
    position: relative;
    width: 319px;
    display: block;
    left: 36px;
  }
}
.contact-subtitle .subtitle-img .img-desk {
  display: block;
}
@media (max-width: 991px) {
  .contact-subtitle .subtitle-img .img-desk {
    display: none;
  }
}
.feedback-form .form-item {
  display: flex;
  padding-left: 108px;
}
@media (max-width: 991px) {
  .feedback-form .form-item {
    display: block;
    padding-left: 20px;
    padding-right: 20px;
  }
}
.feedback-form label {
  width: 93px;
  padding-top: 15px;
  margin-right: 20px;
}
@media (max-width: 991px) {
  .feedback-form label {
    margin-bottom: 10px;
    padding-top: 0;
    margin-right: 0;
  }
}
.feedback-form .form-item + .form-item {
  margin-top: 30px;
}
.feedback-form .form-item input.error {
  background-color: #FFD8CC;
}
.feedback-form .form-input p {
  margin-top: 10px;
}
@media (max-width: 991px) {
  .feedback-form .form-input p {
    padding-left: 0;
  }
}
.feedback-form p.error {
  color: #ed4918;
}
input {
  height: 60px;
  border-radius: 5px;
  border: 1px solid transparent;
  width: 100%;
  font-size: 20px;
  font-weight: bold;
  padding-left: 15px;
  padding-right: 15px;
}
input:hover,
input :active {
  border: 1px solid #ED805F;
}
.form-input {
  width: 630px;
}
@media (max-width: 767px) {
  .form-input {
    width: 335px;
  }
}
@media (max-width: 374px) {
  .form-input {
    width: 280px;
  }
}
.form-input--short {
  width: 520px;
  margin-bottom: 60px;
}
@media (max-width: 767px) {
  .form-input--short {
    width: 300px;
  }
}
@media (max-width: 374px) {
  .form-input--short {
    width: 280px;
  }
}
.feedback-form:last-child {
  margin-bottom: 60px;
}
.contact-form-footer {
  display: flex;
  justify-content: space-between;
  background-color: #363E4D;
  padding-top: 30px;
  padding-bottom: 100px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}
@media (max-width: 991px) {
  .contact-form-footer {
    display: block;
    padding-left: 20px;
    padding-right: 20px;
  }
}
.form-footer {
  padding-left: 220px;
}
@media (max-width: 991px) {
  .form-footer {
    padding-left: 0;
    margin-bottom: 34px;
  }
}
.form-btn {
  margin-bottom: 30px;
}
.form-btn .big-button {
  width: 300px;
}
@media (max-width: 374px) {
  .form-btn .big-button {
    width: 240px;
  }
}
.disclaimer {
  padding-right: 110px;
}
.disclaimer svg {
  margin-bottom: 15px;
}
.disclaimer-text {
  width: 300px;
}
@media (max-width: 374px) {
  .disclaimer-text {
    width: 298px;
  }
}
/*contact-form-success*/
.contact-form.contact-form-success {
  position: relative;
  background-color: #363E4D;
}
.contact-form.contact-form-success .contact-subtitle {
  background-color: #252A34;
}
.contact-form.contact-form-success .success-title {
  display: block;
}
.contact-form.contact-form-success .register-title {
  display: none;
}
.contact-form.contact-form-success .success-icon {
  position: absolute;
  display: block;
  top: -61px;
  left: 125px;
}
@media (max-width: 991px) {
  .contact-form.contact-form-success .success-icon {
    top: -74px;
    left: -29px;
  }
}
.contact-form.contact-form-success .subtitle-text {
  display: none;
}
.contact-form.contact-form-success .subtitle-text__success {
  display: block;
  max-width: 610px;
}
@media (min-width: 992px) and (max-width: 1364px) {
  .contact-form.contact-form-success .subtitle-text__success {
    width: 435px;
  }
}
.contact-form.contact-form-success .subtitle-text__success p + p {
  padding-top: 20px;
}
.contact-form.contact-form-success .success-message {
  display: block;
  padding-left: 220px;
  padding-bottom: 100px;
}
@media (max-width: 991px) {
  .contact-form.contact-form-success .success-message {
    padding-left: 20px;
    padding-bottom: 125px;
  }
}
.contact-form.contact-form-success .info-text--success {
  display: block;
  max-width: 610px;
  margin-bottom: 30px;
}
.contact-form.contact-form-success .social-button--block {
  display: flex;
}
@media (max-width: 767px) {
  .contact-form.contact-form-success .social-button--block {
    flex-direction: column;
  }
}
.contact-form.contact-form-success .social-button--block a {
  display: inline-flex;
  line-height: 1.4;
  text-align: center;
  align-items: center;
  width: 220px;
}
.contact-form.contact-form-success .social-button {
  height: 60px;
  color: #ffffff;
  line-height: 60px;
  display: inline-flex;
  justify-content: center;
  width: 220px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #37AEE2;
  align-items: center;
}
.contact-form.contact-form-success .social-button span + span {
  padding-left: 15px;
}
.contact-form.contact-form-success .social-button i {
  font-size: 30px;
}
.contact-form.contact-form-success .social-button--vk {
  background-color: #4D76A1;
}
.contact-form.contact-form-success .social-button + .social-button {
  margin-left: 20px;
}
@media (max-width: 767px) {
  .contact-form.contact-form-success .social-button + .social-button {
    margin-top: 20px;
    margin-left: 0;
  }
}
.special-wrapper {
  overflow: hidden;
  position: relative;
}
.special-wrapper .social-icon {
  filter: drop-shadow(0px 10px 40px rgba(37, 42, 52, 0.25));
  position: absolute;
  bottom: -50px;
  right: 153px;
  transform: translate(-10%, -65%);
  z-index: 5;
}
@media (min-width: 991px) and (max-width: 1364px) {
  .special-wrapper .social-icon {
    bottom: 20px;
    right: 50px;
  }
}
@media (max-width: 991px) {
  .special-wrapper .social-icon {
    transform: translate(31%, -45%);
    width: 215px;
    bottom: 36px;
    right: -15px;
  }
}
@media (max-width: 1364px) {
  .special-wrapper .social-icon img {
    width: 215px;
  }
}
.special-wrapper #social-icon {
  display: none;
}
.special-wrapper #social-icon.social-icon--visible {
  display: block;
}
.faq {
  margin-top: 245px;
}
@media (max-width: 767px) {
  .faq {
    margin-top: 140px;
    overflow: hidden;
  }
}
.faq .double-block__row {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .faq .double-block__row {
    display: block;
  }
}
.faq .white-block {
  width: 49%;
  border-radius: 5px;
  overflow: hidden;
}
@media (max-width: 767px) {
  .faq .white-block {
    width: 100%;
    margin-bottom: 30px;
  }
}
.faq .white-block .title-2 h2 {
  max-width: 620px;
  margin-bottom: 110px;
}
@media (max-width: 767px) {
  .faq .white-block .title-2 h2 {
    margin-bottom: 50px;
  }
}
.faq .white-block .double-block-content {
  background-color: #FFFFFF;
  border-radius: 5px;
  padding-top: 50px;
}
@media (max-width: 424px) {
  .faq .white-block .double-block-content {
    overflow: hidden;
  }
}
.faq .white-block .double-block-content p.text-3 {
  line-height: 1.5;
}
.faq .white-block .img-block {
  width: 360px;
  margin-right: auto;
  margin-left: auto;
  overflow: hidden;
  margin-bottom: 12px;
}
@media (max-width: 767px) {
  .faq .white-block .img-block {
    border-radius: 5px;
    margin-bottom: 14px;
  }
}
.faq .white-block .img-block img {
  position: relative;
}
@media (max-width: 424px) {
  .faq .white-block .img-block img {
    right: -118px;
  }
}
.faq .white-block .block-content {
  padding-right: 70px;
  padding-left: 30px;
  padding-bottom: 60px;
}
@media (max-width: 767px) {
  .faq .white-block .block-content {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.faq .white-block .badge {
  background-color: #2F80ED;
}
.faq .white-block .text-block h4 {
  margin-top: 20px;
  margin-bottom: 15px;
}
.faq .pink-block {
  width: 49%;
  background-color: #FAC7EA;
  border-radius: 5px;
  padding-top: 25px;
  padding-left: 30px;
  padding-right: 10px;
  padding-bottom: 60px;
  overflow: hidden;
}
@media (max-width: 767px) {
  .faq .pink-block {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 424px) {
  .faq .pink-block {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
.faq .pink-block .badge {
  margin-bottom: 40px;
  background-color: #F5F5F7;
  color: #000000;
}
@media (max-width: 424px) {
  .faq .pink-block .badge {
    position: absolute;
    left: 50px;
    margin-bottom: 0;
    z-index: 1;
  }
}
.faq .pink-block .img-block {
  width: 620px;
}
@media (max-width: 991px) {
  .faq .pink-block .img-block {
    position: relative;
    width: 596px;
    left: -144px;
  }
}
@media (max-width: 424px) {
  .faq .pink-block .img-block {
    width: 536px;
    right: 113px;
    top: -30px;
  }
}
@media (max-width: 374px) {
  .faq .pink-block .img-block {
    width: 434px;
    right: 95px;
    top: 8px;
  }
}
.faq .pink-block .text-block {
  padding-right: 60px;
}
@media (max-width: 767px) {
  .faq .pink-block .text-block {
    padding-right: 0;
  }
}
.faq .pink-block .text-block p.text-3 {
  line-height: 1.5;
}
.faq .pink-block .text-block h4 {
  margin-top: 98px;
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .faq .pink-block .text-block h4 {
    margin-top: 20px;
  }
}
@media (max-width: 424px) {
  .faq .pink-block .text-block h4 {
    margin-top: -8px;
  }
}
@media (max-width: 374px) {
  .faq .pink-block .text-block h4 {
    margin-top: 5px;
  }
}
.faq .single-block-wrapper {
  position: relative;
  padding-bottom: 145px;
}
@media (max-width: 1199px) {
  .faq .single-block-wrapper {
    padding-bottom: 198px;
  }
}
@media (min-width: 992px) and (max-width: 1024px) {
  .faq .single-block-wrapper {
    padding-bottom: 242px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .faq .single-block-wrapper {
    padding-bottom: 100px;
  }
}
@media (max-width: 767px) {
  .faq .single-block-wrapper {
    padding-bottom: 370px;
  }
}
.faq .single-block-wrapper .badge {
  position: absolute;
  top: 24px;
  left: 28px;
  background-color: #f5f5f7;
  color: #000000;
}
@media (max-width: 767px) {
  .faq .single-block-wrapper .badge {
    margin-bottom: 0;
  }
}
@media (max-width: 424px) {
  .faq .single-block-wrapper .badge {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 1;
  }
}
.faq .single-block-wrapper .single-block {
  display: flex;
  margin-top: 50px;
  overflow: hidden;
  background-color: #EAF0F5;
  border-radius: 5px;
  align-items: flex-end;
}
@media (max-width: 1199px) {
  .faq .single-block-wrapper .single-block {
    align-items: flex-start;
  }
}
@media (max-width: 767px) {
  .faq .single-block-wrapper .single-block {
    background-color: #FFFFFF;
    flex-direction: column-reverse;
    margin-top: 30px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
.faq .single-block-wrapper .single-block .round-icon {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 0;
  right: -40px;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background-color: #6EB883;
  box-shadow: 0 10px 60px rgba(110, 184, 131, 0.25), 0 10px 60px rgba(37, 42, 52, 0.15);
  color: #FFFFFF;
}
@media (max-width: 1364px) {
  .faq .single-block-wrapper .single-block .round-icon {
    right: 0;
  }
}
@media (max-width: 991px) {
  .faq .single-block-wrapper .single-block .round-icon {
    right: -80px;
  }
}
@media (min-width: 425px) and (max-width: 767px) {
  .faq .single-block-wrapper .single-block .round-icon {
    right: -53px;
  }
}
@media (max-width: 424px) {
  .faq .single-block-wrapper .single-block .round-icon {
    left: 0;
    bottom: 0;
  }
}
.faq .single-block-wrapper .single-block .round-icon span.number {
  font-size: 100px;
  line-height: 1;
  position: relative;
  top: -12px;
  left: -20px;
}
.faq .single-block-wrapper .single-block .round-icon sup {
  font-size: 60px;
  line-height: 0.8;
  font-weight: bold;
}
.faq .single-block-wrapper .single-block .round-icon span.text-bold {
  width: 220px;
}
.faq .single-block-wrapper .single-block .single-block-content {
  display: contents;
  flex-direction: row-reverse;
}
.faq .single-block-wrapper .single-block .text-block {
  max-width: 590px;
  padding-left: 30px;
  padding-bottom: 60px;
  margin-right: 80px;
}
@media (min-width: 767px) and (max-width: 1199px) {
  .faq .single-block-wrapper .single-block .text-block {
    width: 100%;
    padding-top: 75px;
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .faq .single-block-wrapper .single-block .text-block {
    padding-top: 20px;
    padding-right: 20px;
    margin-right: 0;
  }
}
.faq .single-block-wrapper .single-block .text-block p.text-3 {
  line-height: 1.5;
}
.faq .single-block-wrapper .single-block .text-block h4 {
  margin-bottom: 15px;
}
.faq .single-block-wrapper .single-block .img-block {
  max-width: 620px;
  background-color: #EAF0F5;
}
@media (max-width: 767px) {
}
@media (max-width: 424px) {
  .faq .single-block-wrapper .single-block .img-block {
    width: 335px;
  }
}
@media (max-width: 424px) {
  .faq .single-block-wrapper .single-block .img-block img {
    transform: translateX(85px);
  }
}
.footer {
  padding-bottom: 70px;
}
.footer .container {
  padding-top: 45px;
  border-top: 1px solid rgba(73, 82, 102, 0.5);
}
.footer-nav {
  display: flex;
  align-items: center;
}
@media (max-width: 1199px) {
  .footer-nav {
    flex-direction: column;
    align-items: flex-start;
  }
}
.footer-nav__col {
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .footer-nav__col {
    display: block;
  }
}
.footer-logo {
  margin-right: 60px;
}
@media (max-width: 767px) {
  .footer-logo {
    margin-right: 0px;
    margin-bottom: 14px;
  }
}
.rules-doc {
  margin-left: auto;
}
@media (max-width: 1199px) {
  .rules-doc {
    margin-top: 38px;
    margin-left: 0;
  }
}
.rules-doc a + a {
  margin-left: 62px;
}
@media (max-width: 767px) {
  .rules-doc a + a {
    margin-top: 24px;
    margin-left: 0px;
  }
}
.footer-bottom {
  margin-top: 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 991px) {
  .footer-bottom {
    flex-direction: column;
    margin-top: 60px;
    align-items: flex-start;
  }
  .footer-bottom .footer-right {
    margin-top: 40px;
  }
}
.footer-bottom p {
  opacity: .5;
  font-size: 16px;
  line-height: 22px;
}
.rules-doc {
  display: flex;
}
@media (max-width: 767px) {
  .rules-doc {
    display: block;
    margin-left: 0;
  }
  .rules-doc a {
    width: fit-content;
    display: block;
  }
}
.header {
  position: relative;
  z-index: 55;
}
.header.active {
  background-color: #F4E3E0;
}
.header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1330px;
  margin-left: auto;
  margin-right: auto;
}
.logo {
  width: 204px;
}
@media (max-width: 424px) {
  .logo {
    margin-left: -26px;
  }
}
.header-menu nav a {
  font-size: 20px;
  line-height: 1.4;
  color: #252A34;
  transition: .5s;
  border-bottom: 1px dashed rgba(37, 42, 52, 0.2);
  padding-bottom: 5px;
}
.header-menu nav a:hover {
  color: #ED805F;
  border-bottom-color: rgba(237, 73, 24, 0.2);
}
.header-menu nav a + a {
  margin-left: 50px;
}
.header-menu nav a.link-button {
  height: 54px;
  line-height: 52px;
  padding-left: 30px;
  padding-right: 30px;
  display: inline-block;
  transition: .5s;
  color: #252A34;
  border: 1px solid #252A34;
  border-radius: 5px;
}
.header-menu nav a.link-button:hover {
  color: #ED805F;
  border-color: #ed4918;
}
@media (max-width: 991px) {
  .header-menu {
    display: none;
  }
}
.mobile-menu {
  position: fixed;
  display: none;
  padding-top: 165px;
  padding-bottom: 90px;
  padding-left: 20px;
  padding-right: 20px;
  height: 100vh;
  max-height: 786px;
  overflow: auto;
}
.mobile-menu::-webkit-scrollbar {
  width: 3px;
  height: 6px;
}
.mobile-menu::-webkit-scrollbar-track {
  background-color: #dedede;
}
.mobile-menu::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: #252A34;
}
@media (max-width: 991px) {
  .mobile-menu {
    display: block;
    top: 0;
    left: 0;
    right: 0;
    background-color: #F4E3E0;
    transform: translateX(-100%);
    transition: .5s;
    z-index: 50;
  }
}
@media (max-width: 374px) {
  .mobile-menu {
    padding-right: 10px;
  }
}
.mobile-menu .mobile-menu__list ul {
  max-width: 335px;
}
.mobile-menu .mobile-menu__list li + li {
  margin-top: 32px;
}
.mobile-menu .mobile-menu__list li a {
  font-size: 24px;
  line-height: 30px;
  font-family: 'Commissioner';
  font-weight: 700;
  color: #252A34;
  border-bottom: 1px dashed rgba(37, 42, 52, 0.2);
}
.mobile-menu .mobile-menu__list .text-3 {
  margin-top: 20px;
  line-height: 1.36;
}
.mobile-menu-btn {
  margin-top: 48px;
  display: flex;
  position: relative;
  width: 245px;
}
@media (max-width: 374px) {
  .mobile-menu-btn {
    width: 200px;
  }
}
.mobile-menu-btn a {
  display: block;
  width: 245px;
  height: 70px;
  border: 1px solid #252A34;
  line-height: 68px;
  border-radius: 5px;
  font-size: 20px;
  font-family: 'Roboto Regular';
  text-align: center;
  color: #252A34;
  transition: .5s;
}
@media (max-width: 374px) {
  .mobile-menu-btn a {
    width: 200px;
  }
}
.mobile-menu-btn a:hover {
  border-color: #ED4918;
  color: #ED4918;
}
.mobile-menu-btn .mobile-menu-btn__img {
  position: absolute;
  z-index: 5;
  right: -94px;
  top: 0;
}
@media (max-width: 374px) {
  .mobile-menu-btn .mobile-menu-btn__img {
    right: -80px;
    width: 90px;
  }
}
.mobile-menu.active {
  transform: translateX(0);
}
#modalForm .modal-dialog {
  max-width: 100%;
}
#modalForm .feedback-form:last-child {
  margin-bottom: 0;
}
#modalForm .contact-form {
  margin-bottom: 0;
  margin-top: 0;
  padding: 0;
}
#modalForm .contact-form-title {
  padding-top: 40px;
}
@media (max-width: 767px) {
  #modalForm .contact-form-title {
    padding-top: 80px;
  }
}
#modalForm .modal-body {
  padding: 0;
}
#modalForm .close {
  position: absolute;
  top: 24px;
  right: 24px;
  z-index: 10;
}
@media (max-width: 767px) {
  #modalForm .close {
    top: 20px;
    right: 20px;
  }
}
#modalForm .close svg {
  transition: .5s;
}
#modalForm .close:hover svg {
  transform: rotate(360deg);
}
#modalForm .close path {
  fill: #FFFFFF;
}
@media (max-width: 767px) {
  #modalForm .form-input {
    width: 318px;
  }
}
@media (max-width: 374px) {
  #modalForm .form-input {
    width: 260px;
  }
}
@media (max-width: 374px) {
  #modalForm .form-input {
    width: 260px;
  }
}
@media (max-width: 767px) {
  #modalForm .preview-content__data p + p {
    margin-left: 39px;
  }
}
@media (max-width: 374px) {
  #modalForm .preview-content__data p + p {
    margin-left: 24px;
  }
}
#modalForm .success-icon {
  top: -27px;
  left: 103px;
}
@media (max-width: 991px) {
  #modalForm .success-icon {
    top: -40px;
    left: -12px;
  }
}
@media (max-width: 767px) {
  #modalForm .success-icon {
    top: -26px;
    left: -30px;
  }
}
#modalForm .social-icon {
  bottom: -160px;
  right: 90px;
}
@media (max-width: 1199px) {
  #modalForm .social-icon {
    bottom: -75px;
    right: 50px;
  }
}
@media (max-width: 991px) {
  #modalForm .social-icon {
    bottom: 0;
    right: 90px;
  }
}
@media (max-width: 767px) {
  #modalForm .social-icon {
    bottom: -60px;
    right: -34px;
  }
}
#modalForm .social-icon img {
  width: 300px;
}
@media (max-width: 1200px) {
  #modalForm .social-icon img {
    width: 240px;
  }
}
@media (max-width: 767px) {
  #modalForm .social-icon img {
    width: 183px;
  }
}
.preview {
  margin-top: 64px;
}
@media (max-width: 1199px) {
  .preview {
    margin-top: 34px;
  }
}
@media (max-width: 767px) {
  .preview {
    margin-top: 14px;
  }
}
@media (max-width: 767px) {
  .preview .preview-content__data {
    margin-top: 24px;
  }
}
.preview .contact-form {
  position: relative;
  background-color: #495266;
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 16px;
  overflow: hidden;
}
@media (max-width: 767px) {
  .preview .contact-form {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 160px;
  }
}
.preview .contact-form .contact-form-title {
  padding-left: 0;
  padding-right: 0;
}
@media (max-width: 991px) {
  .preview .contact-form .contact-form .title-2 h2 {
    margin-bottom: 20px;
  }
}
.preview .contact-form .subtitle-text {
  margin-bottom: 60px;
}
@media (max-width: 991px) {
  .preview .contact-form .subtitle-text {
    margin-bottom: 30px;
  }
}
.preview .contact-form .subtitle-text p.text-2 {
  font-size: 20px;
}
.preview .contact-form .form-footer {
  padding-left: 0;
  margin-bottom: 0;
}
.preview .contact-form .form-btn {
  margin-bottom: 0;
}
.preview .contact-form .subtitle-img {
  width: 400px;
  position: absolute;
  top: 55px;
  right: 0;
  filter: drop-shadow(0px 10px 40px rgba(37, 42, 52, 0.25));
}
@media (min-width: 992px) and (max-width: 1364px) {
  .preview .contact-form .subtitle-img {
    width: 290px;
    top: 150px;
  }
}
@media (max-width: 991px) {
  .preview .contact-form .subtitle-img {
    width: 295px;
    top: inherit;
    left: 22px;
    bottom: 0;
  }
}
@media (min-width: 426px) and (max-width: 768px) {
  .preview .contact-form .subtitle-img {
    bottom: -26px;
    left: inherit;
  }
}
@media (max-width: 374px) {
  .preview .contact-form .subtitle-img {
    left: -8px;
  }
}
.preview .contact-form .subtitle-img .img-desk {
  display: block;
}
@media (max-width: 991px) {
  .preview .contact-form .subtitle-img .img-desk {
    display: none;
  }
}
.preview .contact-form .subtitle-img .img-mob {
  display: none;
}
@media (max-width: 991px) {
  .preview .contact-form .subtitle-img .img-mob {
    display: block;
  }
}
.preview .container--dark {
  border-radius: 5px 5px 5px 100px;
}
@media (max-width: 767px) {
  .preview .container--dark {
    overflow: hidden;
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
.preview-content {
  background: #252A34;
  border-radius: 5px 5px 5px 100px;
  padding: 50px;
  padding-top: 40px;
  position: relative;
}
@media (max-width: 767px) {
  .preview-content {
    padding-left: 0;
    padding-right: 0;
    padding-top: 30px;
    padding-bottom: 100px;
    border-radius: 5px;
  }
}
.preview-content__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 70px;
}
@media (max-width: 767px) {
  .preview-content__top {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 50px;
  }
}
.preview-content__data {
  display: flex;
}
.preview-content__data p {
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 0.05em;
  font-weight: 700;
  white-space: nowrap;
}
@media (max-width: 374px) {
  .preview-content__data p {
    font-size: 14px;
  }
}
.preview-content__data p span {
  white-space: nowrap;
}
.preview-content__data p + p {
  margin-left: 40px;
}
@media (max-width: 374px) {
  .preview-content__data p + p {
    margin-left: 28px;
  }
}
.preview-content__data p:first-child {
  position: relative;
  padding-left: 34px;
}
.preview-content__data p:first-child:before {
  content: '';
  width: 20px;
  height: 21px;
  background-image: url(../img/icons/calendar.png);
  background-size: contain;
  background-position: 0 0;
  background-repeat: no-repeat;
  position: absolute;
  left: -7px;
  top: -2px;
}
@media (max-width: 1364px) {
  .preview-content__data p:first-child:before {
    left: 0;
    top: 0;
  }
}
.preview-content__title h1,
.preview-content__title .text-1 {
  color: #fff;
}
.preview-content__title .title-1 {
  margin-bottom: 50px;
}
@media (max-width: 767px) {
  .preview-content__title .title-1 {
    margin-bottom: 33px;
  }
}
@media (max-width: 1199px) {
  .preview-content__title .preview-subtitle {
    max-width: 535px;
  }
}
@media (max-width: 767px) {
  .preview-content__title .preview-subtitle {
    max-width: 320px;
  }
}
.preview-content__btn {
  margin-top: 60px;
  position: relative;
}
@media (max-width: 1199px) {
  .preview-content__btn {
    margin-top: 50px;
  }
}
.preview-emoji {
  position: absolute;
  width: 300px;
  right: -90px;
  bottom: 0;
  z-index: 2;
}
@media (max-width: 1364px) {
  .preview-emoji {
    width: 140px;
    right: -60px;
    bottom: -22px;
  }
}
.preview-info {
  margin-top: 145px;
}
@media (max-width: 767px) {
  .preview-info {
    margin-top: 138px;
  }
}
.preview-info__text p {
  color: #fff;
}
.preview-info__text p + p {
  margin-top: 70px;
}
.preview-info__icon {
  height: 100px;
  display: flex;
  align-items: flex-end;
  margin: 20px;
}
@media (max-width: 767px) {
  .preview-info__icon {
    margin-left: 0;
  }
}
.preview-info__column .text-100 {
  color: #6EB883;
}
.preview-info__column .text-bold {
  color: #fff;
  margin-top: 20px;
  width: 300px;
}
.preview-column__flex {
  display: flex;
  align-items: flex-end;
}
.preview-info__head {
  margin-bottom: 55px;
  color: #fff;
}
@media (min-width: 1200px) {
  .preview-info__head span {
    display: block;
  }
}
@media (min-width: 1200px) {
  .preview-info__text.pl-20 {
    padding-left: 20px;
    position: relative;
  }
  .preview-info__text.pl-20:after {
    content: '';
    height: 1px;
    width: 650px;
    background-color: #6EB883;
    position: absolute;
    bottom: -34px;
    left: 20px;
  }
  .preview-info__column.pl-70 {
    padding-left: 70px;
  }
}
@media (max-width: 1199px) {
  .preview-info__column.pl-70 {
    position: relative;
    padding-top: 70px;
    padding-left: 25px;
  }
  .preview-info__column.pl-70:after {
    content: '';
    width: 1px;
    background-color: #6EB883;
    position: absolute;
    bottom: 0px;
    top: 20px;
    left: 0;
  }
}
.question-item {
  display: inline-block;
  position: relative;
  padding: 32px 65px;
  background: #495266;
  border-radius: 50px;
}
@media (max-width: 767px) {
  .question-item {
    padding: 10px 20px;
    border-radius: 24px;
  }
}
.question-item p {
  color: #fff;
  position: relative;
  z-index: 2;
}
.question-item:before {
  content: "";
  position: absolute;
  width: 54px;
  height: 50px;
  background-image: url(../img/tail.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.question-item--right {
  background-color: #2F80ED;
}
.question-item--right:before {
  background-image: url(../img/tail2.png);
  left: auto;
  right: 0;
}
.questions-block {
  margin-top: 140px;
}
.questions-block__row {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1364px) {
  .questions-block__row {
    flex-direction: column;
  }
}
.questions-block__row p {
  color: #fff;
}
.questions-block__row .questions-block__column {
  width: 66%;
}
@media (max-width: 1364px) {
  .questions-block__row .questions-block__column {
    width: 100%;
  }
}
.questions-block__row .questions-block__column-4 {
  width: 300px;
}
@media (max-width: 1364px) {
  .questions-block__row .questions-block__column-4 {
    margin-top: 48px;
    margin-bottom: 48px;
  }
}
.questions-block__row + .questions-block__row {
  margin-top: 40px;
}
@media (max-width: 767px) {
  .questions-block__row + .questions-block__row {
    margin-top: 20px;
  }
}
.questions-block__row.top-0 {
  margin-top: 0;
}
.questions-block__left .question-item {
  margin-left: -4px;
}
@media (max-width: 767px) {
  .questions-block__left .question-item {
    margin-left: -2px;
  }
}
@media (max-width: 767px) {
  .question-item p {
    display: inline-flex;
    flex-direction: column;
  }
}
.questions-block__right {
  margin-top: 70px;
}
.questions-block__right .questions-block__column {
  margin-left: auto;
  width: auto;
}
@media (max-width: 1364px) {
  .questions-block__row.block-reverse {
    flex-direction: column-reverse;
    display: flex;
  }
}
.emoji-group {
  display: flex;
  width: 340px;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  margin-left: auto;
}
@media (max-width: 767px) {
  .emoji-group {
    width: 250px;
    margin-top: 24px;
  }
  .emoji-group img {
    width: 70px;
  }
}
.result {
  margin-top: 227px;
  position: relative;
  overflow: hidden;
  padding-bottom: 60px;
}
@media (max-width: 991px) {
  .result {
    margin-top: 140px;
  }
}
.result .title-2 {
  margin-bottom: 40px;
}
@media (max-width: 991px) {
  .result .title-2 {
    margin-bottom: 50px;
  }
}
.result .result-img {
  position: absolute;
  width: 38.8%;
  max-width: 520px;
  min-width: 312px;
  bottom: 0%;
  right: 3%;
}
@media (max-width: 767px) {
  .result .result-img {
    right: -9%;
    bottom: -6%;
  }
}
.result .result-list {
  position: relative;
  z-index: 1;
}
.result .result-list ul,
.result .result-list .text-2 {
  padding-left: 99px;
}
@media (max-width: 1364px) {
  .result .result-list ul,
  .result .result-list .text-2 {
    padding-left: 30px;
  }
}
@media (max-width: 1199px) {
  .result .result-list ul,
  .result .result-list .text-2 {
    padding-left: 0;
  }
}
.result .result-list li + li {
  margin-top: 60px;
}
@media (max-width: 767px) {
  .result .result-list li + li {
    margin-top: 70px;
  }
}
.result .result-list .text-2 {
  margin-top: 30px;
}
@media (max-width: 767px) {
  .result .result-list .text-2 {
    margin-top: 30px;
  }
}
.result ul li.decor-label {
  margin-top: 30px !important;
}
@media (max-width: 424px) {
  .result ul li.decor-label {
    margin-top: 59px !important;
  }
}
.result .decor-label .badge {
  margin-bottom: 10px;
  background-color: #2F80ED;
}
@media (max-width: 767px) {
  .result .decor-label .badge {
    margin-top: 14px;
  }
}
.review {
  margin-top: 200px;
}
@media (max-width: 767px) {
  .review {
    margin-top: 90px;
  }
}
.review-head {
  position: relative;
  overflow: hidden;
  padding-bottom: 60px;
}
.review-head .title-2 {
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .review-head .title-2 {
    margin-bottom: 30px;
  }
}
@media (max-width: 1364px) {
  .review-head .text-2 {
    max-width: 500px;
  }
}
@media (max-width: 374px) {
  .review-head .text-2 {
    font-size: 17px;
  }
}
.review-head__img {
  position: absolute;
  right: 45px;
  top: 0;
}
@media (max-width: 1364px) {
  .review-head__img {
    width: 160px;
    top: 55px;
  }
}
.review-item {
  box-shadow: 0px 10px 30px rgba(37, 42, 52, 0.1);
  border-radius: 5px;
  margin-bottom: 50px;
  max-width: 396px;
}
@media (max-width: 767px) {
  .review-item {
    width: 335px;
    margin-left: auto;
    margin-right: auto;
  }
}
.review-item--video {
  box-shadow: none;
}
.review-content {
  height: 980px;
  overflow: hidden;
  transition: .5s;
}
@media (max-width: 1364px) {
  .review-content {
    height: 896px;
  }
}
@media (max-width: 1199px) {
  .review-content {
    height: 727px;
  }
}
@media (max-width: 991px) {
  .review-content {
    height: 560px;
  }
}
@media (max-width: 767px) {
  .review-content {
    height: 1327px;
  }
}
.review {
  position: relative;
}
@media (max-width: 767px) {
  .review {
    overflow: hidden;
  }
}
.review .review__img {
  position: absolute;
  right: -45px;
  top: 175px;
  z-index: 5;
}
@media (max-width: 767px) {
  .review .review__img {
    width: 160px;
    top: 222px;
    right: -40px;
  }
}
.review-content_btn {
  margin-top: 75px;
}
@media (max-width: 767px) {
  .review-content_btn {
    margin-top: 60px;
  }
}
.review-content_btn button.hide {
  display: none;
}
.video-wrapper {
  position: relative;
}
.video-wrapper video {
  width: 396px;
  height: 700px;
}
@media (max-width: 1364px) {
  .video-wrapper video {
    width: 360px;
    height: 636px;
  }
}
@media (max-width: 1199px) {
  .video-wrapper video {
    width: 290px;
    height: 512px;
  }
}
@media (max-width: 991px) {
  .video-wrapper video {
    width: 216px;
    height: 382px;
  }
}
@media (max-width: 767px) {
  .video-wrapper video {
    width: 335px;
    height: 594px;
  }
}
.btn-play {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  background-color: rgba(237, 128, 95, 0.9);
  box-shadow: 0px 10px 20px rgba(37, 42, 52, 0.15);
  position: absolute;
  left: 20px;
  bottom: 30px;
  z-index: 5;
  border-radius: 50%;
}
.btn-play img {
  position: relative;
  right: -5px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .btn-play {
    width: 100px;
    height: 100px;
    bottom: 20px;
  }
  .btn-play img {
    width: 35px;
  }
}
.preview-footer {
  margin-top: 106px;
}
@media (max-width: 1199px) {
  .preview-footer {
    margin-top: 140px;
  }
}
@media (max-width: 991px) {
  .preview-footer {
    margin-top: 110px;
  }
}
.preview-speaker__block {
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .preview-speaker__block {
    display: block;
  }
}
.preview-speaker__photo {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-right: 30px;
}
@media (max-width: 767px) {
  .preview-speaker__photo {
    width: 200px;
    height: 200px;
    margin-bottom: 30px;
  }
}
.preview-speaker__date {
  color: #fff;
}
.preview-speaker__date .text-upper {
  margin-top: 15px;
  text-transform: uppercase;
}
.preview-speaker__date .text-1 {
  font-size: 28px;
}
.preview-speaker__date .text-2 {
  font-size: 20px;
}
.preview-footer__column {
  display: flex;
}
.preview-footer__column .text-upper {
  white-space: nowrap;
}
@media (max-width: 1199px) {
  .preview-footer__column {
    margin-top: 70px;
  }
}
@media (max-width: 767px) {
  .preview-footer__column {
    flex-direction: column;
  }
}
.preview-footer__column p {
  color: #fff;
}
.preview-footer__column p.text-green {
  color: #6EB883;
}
.footer__column-item p:first-child {
  margin-bottom: 20px;
}
.footer__column-item + .footer__column-item {
  margin-left: 50px;
}
@media (max-width: 767px) {
  .footer__column-item + .footer__column-item {
    margin-left: 0;
    margin-top: 50px;
  }
}
@media (min-width: 1365px) {
  .col-xl-7.pl-100 {
    padding-left: 102px;
    margin-top: -7px;
  }
}
.webinar-theme {
  margin-top: 240px;
}
@media (max-width: 991px) {
  .webinar-theme {
    margin-top: 128px;
  }
}
.webinar-theme__head {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 25px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
@media (max-width: 991px) {
  .webinar-theme__head {
    display: block;
    padding-bottom: 12px;
  }
}
.webinar-theme__content {
  padding-top: 40px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 140px;
  background: #495266;
  border-radius: 5px 5px 100px 5px;
}
@media (max-width: 991px) {
  .webinar-theme__content {
    padding-bottom: 36px;
    border-radius: 5px;
  }
}
@media (max-width: 424px) {
  .webinar-theme__content {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.webinar-theme__content .title-2 h2 {
  color: #fff;
}
@media (max-width: 991px) {
  .webinar-theme__content .title-2 {
    margin-bottom: 20px;
  }
}
.info-row {
  display: flex;
  justify-content: space-between;
}
.info-row + .info-row {
  margin-top: 80px;
}
@media (max-width: 991px) {
  .info-row {
    display: block;
  }
  .info-row + .info-row {
    margin-top: 60px;
  }
}
.info-row__column {
  width: 47%;
  max-width: 555px;
  padding: 20px 40px 36px 30px;
}
@media (max-width: 991px) {
  .info-row__column {
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 20px;
    padding-right: 20px;
  }
  .info-row__column + .info-row__column {
    margin-top: 60px;
  }
}
.info-row__column--p0 {
  padding-left: 0;
  padding-right: 0;
}
.info-row__column--bg {
  padding: 20px 40px 36px 30px;
  background: #5b6375;
  border-radius: 5px;
  padding-top: 20px;
  padding-bottom: 35px;
}
@media (max-width: 767px) {
  .info-row__column--bg {
    padding: 20px;
    padding-bottom: 40px;
  }
}
.webinar-theme__text {
  padding-top: 100px;
}
@media (max-width: 767px) {
  .webinar-theme__text {
    padding-top: 61px;
  }
}
.webinar-theme__text p.text-100 {
  color: #6EB883;
  margin-bottom: 20px;
}
.webinar-theme__content .title-3 h3,
.webinar-theme__content p {
  color: #fff;
}
.webinar-theme__content .title-3 {
  margin-bottom: 14px;
}
@media (max-width: 767px) {
  .webinar-theme__content .title-3 {
    margin-bottom: 20px;
  }
}
.info-row__column--border {
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.3);
}
@media (max-width: 991px) {
  .info-row__column--border {
    padding-top: 20px;
    padding-bottom: 40px;
  }
}
.info-row__column--300 {
  width: 300px;
  padding-right: 30px;
}
@media (max-width: 767px) {
  .info-row__column--300 {
    width: 100%;
    max-width: 335px;
  }
}
.info-row__column--70 {
  min-width: 68%;
  max-width: 810px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .info-row__column--70 {
    min-width: 60%;
  }
}
.webinar-theme__footer {
  margin-top: 90px;
  position: relative;
}
@media (max-width: 767px) {
  .webinar-theme__footer {
    margin-top: 60px;
  }
}
.webinar-theme__footer .badge {
  position: absolute;
  background-color: #2F80ED;
  top: 17px;
  left: 30px;
  z-index: 5;
}
.emoji-row {
  display: flex;
  overflow: hidden;
  justify-content: center;
  margin-bottom: 50px;
}
.emoji-row__item {
  min-width: 200px;
  max-width: 200px;
}
.emoji-row__item + .emoji-row__item {
  margin-left: 60px;
}
@media (max-width: 1199px) {
  .emoji-row__item {
    min-width: 146px;
    max-width: 146px;
  }
  .emoji-row__item + .emoji-row__item {
    margin-left: 20px;
  }
}
@media (max-width: 767px) {
  .emoji-row__item img {
    position: relative;
    left: -80px;
  }
}
.info-row__column.info-row__column-emoji {
  padding-left: 0;
  padding-right: 0;
  background: #252a34;
  box-shadow: 0px 10px 40px rgba(37, 42, 52, 0.25);
  border-radius: 15px;
  padding-top: 115px;
  padding-bottom: 60px;
  position: relative;
}
@media (max-width: 767px) {
  .info-row__column.info-row__column-emoji {
    padding-top: 102px;
    padding-bottom: 50px;
  }
}
.emoji-row__text {
  padding-left: 30px;
  padding-right: 40px;
}
@media (max-width: 767px) {
  .emoji-row__text {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.textbox a.text-2 {
  color: #fff;
  display: inline-block;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  margin-top: 20px;
  transition: .5s;
}
.textbox a.text-2:hover {
  color: #ED805F;
  border-bottom: 1px solid rgba(237, 73, 24, 0.2);
}
.info-row__logo {
  position: relative;
  margin-bottom: 40px;
  margin-top: auto;
  width: 240px;
}
.info-row__logo:after {
  content: "";
  top: 35px;
  bottom: 35px;
  left: 38px;
  right: 38px;
  transform: rotate(-17deg);
  position: absolute;
  background-color: #fff;
  z-index: 1;
}
.info-row__logo img {
  z-index: 2;
  position: relative;
}
.info-row__column--flex {
  display: flex;
  flex-direction: column;
  padding-bottom: 60px;
  padding-left: 0;
  padding-right: 0;
}
.speaker {
  padding-top: 40px;
  margin-top: 130px;
  padding-bottom: 60px;
  position: relative;
}
@media (max-width: 1364px) {
  .speaker {
    overflow: hidden;
  }
}
@media (min-width: 1200px) and (max-width: 1364px) {
  .speaker {
    padding-top: 90px;
    padding-bottom: 90px;
  }
}
@media (max-width: 424px) {
  .speaker {
    margin-top: 40px;
  }
}
.speaker:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 77.5%;
  background-color: rgba(237, 128, 95, 0.15);
  max-width: 1000px;
  border-radius: 5px;
}
@media (max-width: 1199px) {
  .speaker:after {
    top: 130px;
    bottom: auto;
    height: 730px;
    left: 0;
    right: 0;
    transform: translateX(0%);
    width: 80%;
  }
}
@media (max-width: 767px) {
  .speaker:after {
    top: 130px;
    bottom: auto;
    height: 720px;
    left: 0;
    right: 70px;
    width: 80%;
  }
}
.speaker-data {
  position: relative;
  z-index: 2;
}
.speaker-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 1199px) {
  .speaker-data {
    flex-direction: column;
    align-items: unset;
  }
}
.speaker-data__column {
  width: 48%;
  max-width: 620px;
}
@media (max-width: 1199px) {
  .speaker-data__column {
    width: 100%;
  }
}
.speaker-data__row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}
@media (max-width: 767px) {
  .speaker-data__row {
    display: block;
  }
}
.speaker-data__row-item {
  width: 285px;
}
@media (max-width: 767px) {
  .speaker-data__row-item + .speaker-data__row-item {
    margin-top: 40px;
  }
}
.speaker-data__row-item p.text-3 {
  line-height: 1.25;
}
.speaker-data__img {
  max-width: 620px;
  width: 48%;
  margin-right: 50px;
  min-width: 400px;
  position: relative;
}
@media (max-width: 1199px) {
  .speaker-data__img {
    margin-bottom: 115px;
  }
}
@media (max-width: 767px) {
  .speaker-data__img {
    margin-left: -19%;
    margin-bottom: 115px;
  }
}
.speaker-data__img .speaker-inst {
  position: absolute;
  z-index: 2;
  bottom: -18px;
  left: 4px;
}
@media (max-width: 1199px) {
  .speaker-data__img .speaker-inst {
    bottom: -60px;
    left: -17px;
  }
}
@media (max-width: 767px) {
  .speaker-data__img .speaker-inst {
    bottom: -70px;
    left: 45px;
  }
}
.speaker-data__logo {
  width: 100px;
  height: 100px;
  margin-bottom: 25px;
}
@media (max-width: 424px) {
  .speaker-data__logo {
    margin-bottom: 20px;
  }
}
.speaker-data__img,
.speaker-data__logo {
  border-radius: 50%;
}
.speaker-data__img img,
.speaker-data__logo img {
  border-radius: 50%;
}
.speaker-data__title {
  position: relative;
  margin-bottom: 60px;
}
.speaker-data__title p {
  font-size: 24px;
  line-height: 1.25;
  margin-top: 20px;
}
@media (max-width: 374px) {
  .speaker-data__title p {
    font-size: 23px;
  }
}
@media (max-width: 767px) {
  .speaker-data__title {
    width: 335px;
  }
}
.speaker-data__title .badge {
  position: absolute;
  right: 88px;
  top: -50px;
}
@media (max-width: 767px) {
  .speaker-data__title .badge {
    right: 4px;
    top: -45px;
  }
}
@media (max-width: 374px) {
  .speaker-data__title .badge {
    right: 42px;
  }
}
.warning {
  margin-top: 240px;
}
@media (max-width: 991px) {
  .warning {
    margin-top: 142px;
  }
}
.warning.warning--2 {
  margin-bottom: 240px;
  margin-top: 179px;
}
@media (max-width: 991px) {
  .warning.warning--2 {
    margin-top: 79px;
    margin-bottom: 199px;
  }
}
.warning.warning--3 {
  margin-bottom: 20px;
  margin-top: 100px;
}
@media (max-width: 991px) {
  .warning.warning--3 {
    margin-top: 150px;
  }
}
.warning.warning--3 .badge {
  background-color: #2F80ED;
  margin-left: 24px;
}
@media (max-width: 991px) {
  .warning.warning--3 .badge {
    margin-left: 0;
    margin-top: 20px;
  }
}
.warning.warning--3 .warning-text--flex {
  margin-top: 25px;
  display: flex;
  align-items: center;
}
@media (max-width: 991px) {
  .warning.warning--3 .warning-text--flex {
    display: block;
  }
}
.warning-text p.decor-text {
  line-height: 1.27;
}
.warning-text p + p {
  margin-top: 30px;
}
@media (max-width: 991px) {
  .warning-text p + p {
    margin-top: 15px;
  }
}
