.preview-info{
	margin-top: 145px;

	@media(max-width: 767px){
		margin-top: 138px;
	}
}
.preview-info__text{
	p{
		color: #fff;

	}
	p+p{
		margin-top: 70px;
	}
}
.preview-info__icon{
	height: 100px;
	display: flex;
	align-items: flex-end;
	margin: 20px;

	@media(max-width: 767px){
		margin-left: 0;
	}
}
.preview-info__column .text-100{
	color: @green;
}
.preview-info__column .text-bold{
	color: #fff;
	margin-top: 20px;
	width: 300px;
}
.preview-column__flex{
	display: flex;
	align-items: flex-end;
}
.preview-info__head{
	margin-bottom: 55px;
	color: #fff;
	span{
		
		@media(min-width:1200px){
			display: block;
		}
	}
}
@media(min-width: 1200px){
	.preview-info__text.pl-20{
		padding-left: 20px;
		position: relative;
		&:after{
			content: '';
			height: 1px;
			width: 650px;
			background-color: @green;
			position: absolute;
			bottom: -34px;
			left: 20px;
		}
	}
	.preview-info__column.pl-70{
		padding-left: 70px;
	}
}
@media(max-width:1199px){
	.preview-info__column.pl-70{
		position: relative;
		padding-top: 70px;
		padding-left: 25px;
		&:after{
			content: '';
			
			width: 1px;
			background-color: @green;
			position: absolute;
			bottom: 0px;
			top: 20px;
			left: 0;
		}

	}
}