#modalForm {
  .modal-dialog {
    max-width: 100%;

  }

  .feedback-form:last-child {
    margin-bottom: 0;
  }

  .contact-form {
    margin-bottom: 0;
    margin-top: 0;

    padding: 0;
    //background-color: transparent;
  }

  .contact-form-title {
    padding-top: 40px;

    @media(max-width: 767px){
      padding-top: 80px;
    }
  }

  .modal-body {
    padding: 0;
  }

  .close {
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 10;
    @media (max-width: 767px) {
      top: 20px;
      right: 20px;
    }

    svg {
      .tr;
    }

    &:hover svg {
      transform: rotate(360deg);
    }

    path {
      fill: #FFFFFF;
    }
  }

  .form-input {
    @media (max-width: 767px) {
      width: 318px;
    }

    @media (max-width: 374px) {
      width: 260px;
    }
    @media (max-width: 374px) {
      width: 260px;

    }
  }


  .preview-content__data p + p{
    @media(max-width: 767px){
      margin-left: 39px;
    }

    @media(max-width: 374px){
      margin-left: 24px;
    }
  }


  .success-icon{
    top: -27px;
    left: 103px;

    @media(max-width: 991px){
      top: -40px;
      left: -12px;
    }

    @media(max-width: 767px){
      top: -26px;
      left: -30px;
    }
  }

  .social-icon{
      bottom: -160px;
      right: 90px;

    @media(max-width: 1199px){
      bottom: -75px;
      right: 50px;
    }

    @media(max-width: 991px){
      bottom: 0;
      right: 90px;
    }

    @media(max-width: 767px){
      bottom: -60px;
      right: -34px;
    }

    img{
      width: 300px;

      @media(max-width: 1200px){
        width: 240px;
      }

      @media(max-width: 767px){
        width: 183px;
      }
    }
  }
}