.review {
  margin-top: 200px;
  @media (max-width: 767px) {
    margin-top: 90px;
  }
}

.review-head {
  position: relative;
  overflow: hidden;
  padding-bottom: 60px;

  .title-2 {
    margin-bottom: 20px;
    @media (max-width: 767px) {
      margin-bottom: 30px;
    }
  }

  .text-2 {
    @media (max-width: 1364px) {
      max-width: 500px;
    }

    @media(max-width: 374px){
      font-size: 17px;
    }
  }
}

.review-head__img {
  position: absolute;
  right: 45px;
  top: 0;
  @media (max-width: 1364px) {
    width: 160px;
    top: 55px;
  }
}

.review-item {
  box-shadow: 0px 10px 30px rgba(37, 42, 52, 0.1);
  border-radius: 5px;
  margin-bottom: 50px;
  max-width: 396px;

  @media (max-width: 767px) {
    width: 335px;
    margin-left: auto;
    margin-right: auto;
  }
}

.review-item--video {
  box-shadow: none;
}

.review-content {
  height: 980px;
  overflow: hidden;
  .tr;
  @media (max-width: 1364px) {
    height: 896px;
  }
  @media (max-width: 1199px) {
    height: 727px;
  }
  @media (max-width: 991px) {
    height: 560px;
  }
  @media (max-width: 767px) {
    height: 1327px;
  }
}

.review {
  position: relative;
  @media (max-width: 767px) {
    overflow: hidden;
  }

  .review__img {
    position: absolute;
    right: -45px;
    top: 175px;
    z-index: 5;

    @media (max-width: 767px) {
      width: 160px;
      top: 222px;
      right: -40px;
    }
  }

}

.review-content_btn {
  margin-top: 75px;
  @media(max-width: 767px){
    margin-top: 60px;
  }

  button.hide {
    display: none;
  }
}

.video-wrapper {
  position: relative;

  video {
    width: 396px;
    height: 700px;
    @media (max-width: 1364px) {
      width: 360px;
      height: 636px;

    }
    @media (max-width: 1199px) {
      width: 290px;
      height: 512px;

    }
    @media (max-width: 991px) {
      width: 216px;
      height: 382px;

    }
    @media (max-width: 767px) {
      width: 335px;
      height: 594px;
    }
  }
}

.btn-play {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  background-color: rgba(237, 128, 95, .9);
  box-shadow: 0px 10px 20px rgba(37, 42, 52, 0.15);
  position: absolute;
  left: 20px;
  bottom: 30px;
  z-index: 5;
  border-radius: 50%;

  img {
    position: relative;
    right: -5px;
  }

  @media (min-width: 768px) and(max-width: 1199px) {
    width: 100px;
    height: 100px;
    bottom: 20px;
    img {
      width: 35px;
    }
  }
}